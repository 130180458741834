

import { ThunkAction, ThunkDispatch} from 'redux-thunk';
import {
    AppState, ConfigurationProducts, DataSet, SelectedExtras, PhysicsResult, ProductPrices, Project,
    ProjectConfiguration,
    ProjectConfigurationFields,
    RegistrationData,
    UserSelection, ResetPasswordData, RequestPasswordResetData, AuthState
} from '../types/types'
import DataSetHelper from '../util/DataSetHelper';
import ApiObjectConverter from '../util/ApiObjectConverter';
import AppObjectConverter from '../util/AppObjectConverter';

import {sumPrices, getPrices} from '../modules/configurator/calculator/GetPrices';
import CalculatorFactory from '../modules/configurator/calculator/CalculatorFactory';
import {Dispatch} from 'redux'
import {Action, type PayloadAction} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import I18n from "../i18n/i18n";
import {unknown} from "zod";

const API_ERROR_UNAUTHENTICATED: string = 'API_ERROR_UNAUTHENTICATED';
export const PRICE_CALCULATION_ERROR: string = 'PRICE_CALCULATION_ERROR';
const API_ERROR_ADDING_CONFIGURATION: string = 'API_ERROR_ADDING_CONFIGURATION';

// sidebar
export const SIDEBAR_SHOW: string = 'sidebar/show';
export const SIDEBAR_HIDE: string = 'sidebar/hide';
export const SIDEBAR_TOGGLE: string = 'sidebar/toggle';
export const SIDEBAR_SET_HIDEABILITY: string = 'sidebar/setHideability';

// product
export const PRODUCT_SET_DATASET = 'product/setDataset';
export const PRODUCT_SET_PARAMETER = 'product/setParameter';
export type SetParameterValue = string | SelectedExtras | null;

// project
export const LOAD_PROJECTS = 'project/loadProjects';
export const SELECT_PROJECT = 'project/selectProject';

export const LOGOUT_PROJECT = 'project/logout';

// auth
export const LOGIN = 'auth/login';
export const LOGOUT = 'auth/logout';

export const CHANGE_LANGUAGE = 'language/changeLanguage';

export type SidebarShowAction = {
    type: typeof SIDEBAR_SHOW
};

export type SidebarHideAction = {
    type: typeof SIDEBAR_HIDE
};

export type SidebarToggleAction = {
    type: typeof SIDEBAR_TOGGLE
};

export type SideBarSetHideabilityAction = {
    type: typeof SIDEBAR_SET_HIDEABILITY,
    canBeHidden: boolean
};

export type ProductSetDatasetAction = {
    type: typeof PRODUCT_SET_DATASET,
    dataSet: DataSet
};

export type ProductSetParameterAction = {
    type: typeof PRODUCT_SET_PARAMETER,
    parameter: string,
    value: string | SelectedExtras | null
};

export type LoadProjectsAction = {
    type: typeof LOAD_PROJECTS,
    projects: Project[]
};

export type SelectProjectAction = {
    type: typeof SELECT_PROJECT,
    project: Project | null
};

export type LoginAction = {
    type: typeof LOGIN,
    token: string,
    username: string
};

export type LogoutAction = {
    type: typeof LOGOUT
};

export type ChangeLanguageAction = {
    type: typeof CHANGE_LANGUAGE,
    language: string,
};

export function callApi(
    method: string,
    path: string,
    token: string | null = null,
    body: string | null = null): Promise<Response> {

    let options: RequestInit = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        }
    };

    if (token) {
        options.headers = {
            ...options.headers,
            Authorization: 'Bearer ' + token
        };
    }

    if (body) {
        options.body = body;
    }

    return fetch(process.env.REACT_APP_API_BASE_URL + path, options)
        .then(response => {
            if (response.status === 401) {
                let error = new Error(I18n.t('notifications.notAuthenticated'));
                error.name = API_ERROR_UNAUTHENTICATED;
                throw error;
            }

            return new Promise<Response>((resolve: Function) => {
                resolve(response);
            });
        });
}

export function init() {

    return (dispatch: ThunkDispatch<AppState, null, Action<string>>, getState: () => AppState) => {
       initDatabase()(dispatch, getState, null)
            .then(() => {
                let username = sessionStorage.getItem('auth.username');
                let token = sessionStorage.getItem('auth.token');

                if (username && token) {
                    login(username, token)(dispatch, getState);
                }
            });
    };
}

export function setSidebarHideability(canBeHidden: boolean): PayloadAction<SideBarSetHideabilityAction> {
    return {
        type: SIDEBAR_SET_HIDEABILITY,
        payload: {
            type: SIDEBAR_SET_HIDEABILITY,
            canBeHidden: canBeHidden,
        }

    };
}

export function toggleSidebar() {

    return {
        type: SIDEBAR_TOGGLE
    };
}

export function createInfoNotification(dispatch: Function, message: string) {

    // dismissAfter: 2000
    toast(message, {
        type: 'info',
    });

}

function createErrorNotification(dispatch: Function, message: string) {

    // dismissAfter: 2000
    toast(message, {
        type: 'error',
    });

}

function createUnexpectedErrorNotification(dispatch: Function) {
    createErrorNotification(dispatch, I18n.t('notifications.unexpectedError'));
}

function handleError(dispatch: Function, error: Error) {

    console.log("handleError");
    console.log(dispatch);
    console.log(error);

    if (error.name === API_ERROR_UNAUTHENTICATED) {
        createErrorNotification(dispatch, error.message);

        dispatch(logout());
        return;
    }

    if (error.name === PRICE_CALCULATION_ERROR) {
        createErrorNotification(dispatch, error.message);
        return;
    }

    if (error.name === API_ERROR_ADDING_CONFIGURATION) {
        createErrorNotification(dispatch, error.message);
        return;
    }

    createUnexpectedErrorNotification(dispatch);
}


function initDatabase(): ThunkAction<Promise<void | {}>, AppState, null, Action> {

    return async (dispatch: Dispatch<Action>): Promise<void | {}> => {
        try {
            const response = await callApi('GET', '/data/initial');
            const data = await response.json();
            return dispatch(
                {
                    type: PRODUCT_SET_DATASET,
                    payload: {
                        dataSet: data,
                    }
                }
            );
        } catch (err) {
            console.log("handle initDatabase error");
            // @ts-ignore
            handleError(dispatch, err);

        }
    };
}

export function loadProjects() {

    return (dispatch: ThunkDispatch<AppState, null, Action>, getState: Function) => {
        try {

            let {product, auth} = getState();
            if (!product.dataSet) {
                throw new Error('No product data loaded.');
            }

            let apiConverter: AppObjectConverter = new AppObjectConverter(new DataSetHelper(product.dataSet));

            return callApi('GET', '/projects', auth.token)
                .then(response => response.json())
                .then(apiProjects => {
                        let projects = {};
                        for (let uuid of Object.keys(apiProjects)) {
                            // @ts-ignore
                            projects[uuid] = apiConverter.createProject(apiProjects[uuid]);
                        }

                        dispatch({
                            type: LOAD_PROJECTS,
                            payload: {
                                type: LOAD_PROJECTS,
                                projects: projects,
                            }
                        });
                    }
                )
                .catch(err => handleError(dispatch, err));
        } catch (err) {
            // @ts-ignore
            handleError(dispatch, err);
            return () => { };
        }
    };
}

export function setParameter(parameter: string, value: SetParameterValue): PayloadAction<ProductSetParameterAction> {

    return {
        type: PRODUCT_SET_PARAMETER,
        payload: {
            type: PRODUCT_SET_PARAMETER,
            parameter: parameter,
            value: value,
        }
    };
}

export function projectSelect(project: Project | null): PayloadAction<SelectProjectAction> {
    return {
        type: SELECT_PROJECT,
        payload: {
            type: SELECT_PROJECT,
            project: project
        }
    };
}



function submitDataToServer(
    method: string,
    path: string,
    token: string | null,
    dataObject: {},
    dispatch: Dispatch<Action>
    ) {

    let currentResponse: Response | null = null;

    return callApi(method, path, token, JSON.stringify(dataObject))
        .then(response => {
            currentResponse = response;
            return response.json();
        })
        .then(json => {

                let status = currentResponse !== null && currentResponse.status;

                switch (status) {
                    case 200:
                        break;

                    case 201:
                        return json;

                    case 400:
                        const jsonErrorObject = json.errors.children;

                        console.log(jsonErrorObject)

                        throw {
                            error: new Error(`Unexpected Error: Code ${status}`),
                            errorObject: jsonErrorObject,
                        };

                    default:
                        throw new Error(`Unexpected Error: Code ${status}`);
                }
            }
        )
        .catch(err => {
            console.log("call api error");
            handleError(dispatch, err);
            throw err;
        });
}

export function projectUpdate(locale: string, project: Project, path: string) {

    return (dispatch: any, getState: () => { auth: any; }) => {
        let {auth} = getState();

        return submitDataToServer(
            'PUT',
            path + `?hl=${locale}`,
            auth.token,
            {project: project},
            dispatch
        )
            .then(() => {
                createInfoNotification(dispatch, I18n.t('notifications.project.updated'));
                loadProjects()(dispatch, getState);
            });
    };
}

export function projectCreate(locale: string, project: Project) {

    return (dispatch: any, getState: Function) => {
        let {auth} = getState();

        return submitDataToServer(
            'POST',
            `/projects?hl=${locale}`,
            auth.token,
            {project: project},
            dispatch
        )
            .then((json) => {
                    createInfoNotification(dispatch, I18n.t('notifications.project.created'));
                    dispatch(projectSelect(json));
                    loadProjects()(dispatch, getState);
                }
            );
    };
}

export function registrationCreate(registration: RegistrationData) {

    return (dispatch: Dispatch<Action>, getState: () => { language: any; }) => {
        let { language } = getState();

        return submitDataToServer(
            'POST',
            `/registration?hl=${language.language}`,
            null,
            {registration: registration},
            dispatch
        ).catch((reason: unknown) => {
            throw reason;
        })

    };
}

export function requestPasswordReset(requestResetData: RequestPasswordResetData) {

    return (dispatch: Dispatch<Action>, getState: () => { language: any; }) => {
        let { language } = getState();

        return submitDataToServer(
            'POST',
            `/password-reset/request?hl=${language.language}`,
            null,
            {password: requestResetData},
            dispatch
        );
    };
}

export function resetPassword(resetPasswordData: ResetPasswordData, token: string) {

    return (dispatch: Dispatch<Action>, getState: () => { language: any; }) => {
        let { language } = getState();

        return submitDataToServer(
            'POST',
            `/password-reset/submit?hl=${language.language}`,
            null,
            {password: {...resetPasswordData, token}},
            dispatch
        ).catch((reason: unknown) => {
            throw reason;
        })
    };
}

export function projectRemove(locale: string, project: Project) {
    return (dispatch: any, getState: Function) => {
        let {auth} = getState();

        return callApi('DELETE', '/projects/' + project.uuid + `?hl=${locale}`, auth.token)
            .then(() => {
                createInfoNotification(dispatch, I18n.t('notifications.project.removed'));

                dispatch(projectSelect(null));
                loadProjects()(dispatch, getState);
            })
            .catch(err => handleError(dispatch, err));
    };
}

export function projectAddConfiguration(locale: string,
                                        values: ProjectConfigurationFields,
                                        parameters: UserSelection,
                                        products: ConfigurationProducts) {

    return (dispatch: any, getState: Function) => {
        try {
            let {auth, project, product} = getState();
            let currentProject = project.currentProject;

            const configurations = product.dataSet.configurations;

            if (!currentProject || !currentProject.uuid) {
                throw new Error('Current Project not set or has no uuid');
            }

            let prices: ProductPrices = getPrices(products, parameters, configurations);
            let calculator = CalculatorFactory.getCalculator(products.rooftop.product, parameters);
            let physics: PhysicsResult = calculator ? calculator.calculate(parameters.flowRate) : {
                v: null,
                pt: null,
                lw: null
            };

            return submitDataToServer(
                'POST',
                '/projects/' + currentProject.uuid + `/configurations?hl=${locale}`,
                auth.token,
                {
                    projectConfiguration: {
                        article: products.rooftop.article,
                        ...values,
                        price: sumPrices(prices, values.quantity),
                        parameters: parameters,
                        physics: physics,
                        products: ApiObjectConverter.createConfigurationProducts(products, prices),
                        state: products.state
                    }
                },
                dispatch
            )
                .then(() => {
                    createInfoNotification(dispatch, I18n.t('notifications.projectConfiguration.created'));
                    loadProjects()(dispatch, getState);
                })
                .catch(err => {
                    err.name = API_ERROR_ADDING_CONFIGURATION;
                    handleError(dispatch, err);
                    throw err;
                });
        } catch (err) {
            // @ts-ignore
            handleError(dispatch, err);
            return;
        }
    }
}

export function projectDeleteConfiguration(locale: string, project: Project, configuration: ProjectConfiguration) {
    return (dispatch: any, getState: Function) => {
        let {auth} = getState();

        return callApi(
            'DELETE',
            '/projects/' + project.uuid + '/configurations/' + configuration.uuid + `?hl=${locale}`,
            auth.token
        )
            .then(() => {
                createInfoNotification(dispatch, I18n.t('notifications.projectConfiguration.removed'));

                loadProjects()(dispatch, getState);
            })
            .catch(err => handleError(dispatch, err));
    };
}

export function login(username: string, token: string) {

    sessionStorage.setItem('auth.username', username);
    sessionStorage.setItem('auth.token', token);

    return (dispatch: ThunkDispatch<AppState, null, Action>, getState: any) => {
        dispatch({
            type: LOGIN,
            payload: {
                username: username,
                token: token
            }
        });

        return loadProjects()(dispatch, getState);
    };
}


export function logout() {

    sessionStorage.removeItem('auth.username');
    sessionStorage.removeItem('auth.token');

    return {
        type: LOGOUT,
    };

}

export function authLogout() {
    return {
        type: LOGOUT_PROJECT,
    };
}

export function changeLanguage(language: string) {

    return {
        type: CHANGE_LANGUAGE,
        language: language,
    };

}

export async function downloadFile(path: string) {

    try {
        const response = await callApi('GET', path);
        const data = await response.json();
        console.log(data);
    } catch (err) {
        console.log("handle initDatabase error");
        // @ts-ignore
        handleError(dispatch, err);
    }

}
