import React, {SyntheticEvent} from 'react';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import {Checkbox, CheckboxProps, DropdownProps, Label} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import DatePicker from "react-datepicker";

type LinkProps = {
  text: string;
  url: string;
}

type FormInputProps = {
    label?: string;
    name: string;
    readonly?: boolean;
    mandatory?: boolean;
    link?: LinkProps;
};

const FormCheckbox: React.FC<FormInputProps> = ({
      label,
      name,
      readonly = false,
      mandatory = false,
      link = null,
  }) => {

  const { t } = useTranslation();

  const {
      register,
      formState: { errors },
    control,
  } = useFormContext();

  const inputValue = useWatch({
      name: name,
  });

  let inputClass = '';
  if (inputValue) {
      inputClass += ' has-value';
  }

  let wrapperClass = '';
  if (mandatory) {
    wrapperClass += ' mandatory';
  }

  let hasError = errors[name];
  let errorClass = '';
  if (hasError) {
    errorClass = ' error';
  }

  return (
        <div className={'field ' + wrapperClass + errorClass}>

          <>
            <div>

              <Controller
                  control={control}
                  name={name}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Checkbox
                          type={'checkbox'}
                          id={name}
                          className={'form-checkbox with-link' + inputClass}
                          readOnly={readonly}
                          onChange={onChange}
                          value={value}
                      />
                  )}
              />

              {label &&
                  <>
                      <label htmlFor={name} className='input-label'>
                        {label}
                        {link &&
                            <a href={link.url} target="_blank">{link.text}</a>
                        }
                      </label>
                  </>
              }
            </div>
          </>

          {errors[name] && (
              <Label basic={true} color="red" pointing="above">
                {// @ts-ignore
                  t(errors[name]?.message)
                }
              </Label>
          )}

        </div>
  );
};

export default FormCheckbox;