import React from 'react';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import {Label} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';


type FormDateInputProps = {
    label?: string;
    name: string;
    placeholder?: string;
    readonly?: boolean;
    mandatory?: boolean;
};

const FormInput: React.FC<FormDateInputProps> = ({
      label,
      name,
      placeholder = '',
      readonly = false,
      mandatory = false,
  }) => {

  const {
      register,
      formState: { errors },
      control,
      getValues,
  } = useFormContext();

  const inputValue = useWatch({
      name: name,
  });

  let inputClass = '';
  if (inputValue) {
      inputClass += ' has-value';
  }

  let wrapperClass = '';
  if (mandatory) {
    wrapperClass += ' mandatory';
  }

  let hasError = errors[name];
  let errorClass = '';
  if (hasError) {
    errorClass = ' error';
  }

  return (
      <div className={'field ' + wrapperClass + errorClass}>

        {label &&
          <>
            <label htmlFor={name} className='input-label'>
              {label}
            </label>
          </>
        }

        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value, ref } }) => (
                <DatePicker
                    onChange={onChange} // send value to hook form
                    onBlur={onBlur} // notify when input is touched/blur
                    selected={value}
                    dateFormat="dd.MM.yyyy"
                />
            )}
        />

        {errors[name] && (
            <Label basic={true} color="red" pointing="above">{errors[name]?.message as string}</Label>
        )}
      </div>
  );
};

export default FormInput;