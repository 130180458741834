import React, {useState} from 'react';
import {bindActionCreators} from "@reduxjs/toolkit";
import {Dispatch} from "redux";
import {SelectProjectAction} from "../store/actions";
import * as Action from '../store/actions';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Button, Form, Icon, Message} from "semantic-ui-react";
import FormInput from "../components/form/FormInput";
import {object, string, TypeOf} from "zod";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useParams} from "react-router-dom";



const resetPasswordFormSchema = object({
  password: string().min(6, { message: 'user.formValidatorMessages.min6'}).max(4096, {message: 'user.formValidatorMessages.max4096'}),
  passwordRepeat: string().min(6, { message: 'user.formValidatorMessages.min6'}).max(4096, {message: 'user.formValidatorMessages.max4096'}),
}).superRefine(({ password, passwordRepeat }, ctx) => {
  if (passwordRepeat !== password) {
    ctx.addIssue({
      code: "custom",
      message: "user.component.resetPassword.validation",
      path: ['passwordRepeat']
    });
  }
})


export type ResetPasswordInput = TypeOf<typeof resetPasswordFormSchema>;

type DispatchProps = {
  resetPassword: typeof Action.resetPassword
};

function ResetPasswordForm(props: DispatchProps) {

  const { t } = useTranslation();
  let { token } = useParams();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const methods = useForm<ResetPasswordInput>({
    resolver: zodResolver(resetPasswordFormSchema),
  });

  const {
    handleSubmit,
    formState: { isSubmitSuccessful, isValid },
  } = methods;

  const onSubmitHandler: SubmitHandler<ResetPasswordInput> = async (values) => {

    try {
      await Promise.all([props.resetPassword(values,token ?? '')]).then(() => {
        setIsSubmitted(true);
      })

    } catch (e: unknown) {
      // TODO Show error
    }

  };

  return (
      <FormProvider {...methods}>

        {isSubmitted ?
            <Message positive={true}>
              <Message.Header>{t('user.component.resetPassword.success.headline')}</Message.Header>
              <p>{t('user.component.resetPassword.success.copytext')}</p>
            </Message>
            :

            <Form onSubmit={handleSubmit(onSubmitHandler)}>
              <FormInput
                  name={'password'}
                  type={'password'}
                  label={t('user.component.resetPassword.password')}
                  autoComplete={'new-password'}
              />
              <FormInput
                  name={'passwordRepeat'}
                  type={'password'}
                  label={t('user.component.resetPassword.passwordRepeat')}
                  autoComplete={'new-password'}
              />

              <div>
                <Button type="submit" disabled={!isValid}>
                  <Icon name="send"/> {t('user.component.resetPassword.sendButton')}
                </Button>
              </div>
            </Form>
        }

      </FormProvider>
  );
}


function mapDispatchToProps(dispatch: Dispatch<SelectProjectAction>): DispatchProps {
  return bindActionCreators(
      {
        resetPassword: Action.resetPassword
      },
      dispatch
  );
}

export default connect(null, mapDispatchToProps)(ResetPasswordForm);
