import * as React from 'react';
import { Form, Grid } from 'semantic-ui-react';
import {date, object, preprocess, string, TypeOf, z} from "zod";
import FormInput from "../../../components/form/FormInput";
import I18n from "../../../i18n/i18n";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useEffect, useMemo, useState} from "react";
import {Project} from "../../../types/types";
import FormDateInput from "../../../components/form/FormDateInput";



const ProjectDetailsFormSchema = object({
    job: string().optional(),
    agent: string().optional(),
    field: string().optional(),
    date: preprocess(
        (arg) => (arg === '' ? null : arg),
        z.date().nullish(),
    ),
    customer: string().optional(),
    customerId: string().optional(),
});

type Props = {
    initialValues: Project,
    onChange: Function,
    onSubmit: Function,
    onSubmitSuccess: Function,
};

export type ProjectDetailsInput = TypeOf<typeof ProjectDetailsFormSchema>;

function ProjectDetailsForm(props: Props) {

    const [loading, setLoading] = useState<boolean>(false);

    const methods = useForm<ProjectDetailsInput>({
        // @ts-ignore
        defaultValues: useMemo(() => {
            return props.initialValues;
        }, [props.initialValues]),
        resolver: zodResolver(ProjectDetailsFormSchema),
    });

    const {
        handleSubmit,
        formState: { isSubmitSuccessful },
        reset,
        getValues,
        setError,
    } = methods;

    useEffect(() => {
        props.onChange(methods.formState.isDirty)
    }, [methods.formState.isDirty]);

    const onSubmitHandler: SubmitHandler<ProjectDetailsInput> = async (values) => {

        setLoading(true);

        try {
            // TODO SHow Success message
            let [response] = await Promise.all([props.onSubmit(values)])

            props.onSubmitSuccess();
            setLoading(false);
            reset(getValues());

        } catch (e: unknown) {
            setLoading(false);
            // TODO General solution for error setting for all forms
            // Set errors
            // @ts-ignore
            let errorObject = e.errorObject;
            for (const [key, value] of Object.entries(errorObject)) {
                // @ts-ignore
                if (value && "errors" in value) {
                    // @ts-ignore
                    let errorMessage = value.errors[0];
                    // @ts-ignore
                    setError(key, {message: errorMessage});
                }
            }
        }

       /* props.onSubmit(values).then((data: any) => {
            props.onSubmitSuccess();
            setLoading(false);
            reset(getValues());
        });*/
    };

    const rtLbl = 'projects.component.createProjectModal.label.';

    return (
        <FormProvider {...methods}>
            <Form id="projectDetailForm" onSubmit={handleSubmit(onSubmitHandler)} loading={loading}>
                <Grid columns="equal" stackable={true}>
                    <Grid.Row>
                        <Grid.Column>
                            <FormInput name={'job'} type={'text'} label={I18n.t(rtLbl + 'job')}/>
                            <FormInput name={'agent'} type={'text'} label={I18n.t(rtLbl + 'agent')}/>
                        </Grid.Column>
                        <Grid.Column>
                            <FormInput name={'field'} type={'text'} label={I18n.t(rtLbl + 'field')}/>
                            <FormDateInput name={'date'} label={I18n.t(rtLbl + 'date')}/>
                        </Grid.Column>
                        <Grid.Column>
                            <FormInput name={'customer'} type={'text'} label={I18n.t(rtLbl + 'customer')}/>
                            <FormInput name={'customerId'} type={'text'} label={I18n.t(rtLbl + 'customerId')}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        </FormProvider>
    )

}

export default ProjectDetailsForm;