import { UserSelection } from '../types/types';

export default class Namer {
    public static generateArticleName(format: string, userSelection: UserSelection): string {
        return format
            .replace('{dn}', userSelection.diameter ? userSelection.diameter.toString() : '')
            .replace('{a}', userSelection.sizeA ? userSelection.sizeA.toString() : '')
            .replace('{b}', userSelection.sizeB ? userSelection.sizeB.toString() : '')
            .replace('{angle}', userSelection.roofAngleValue ? userSelection.roofAngleValue.toString() : '')
            .replace('{louverCnt}', userSelection.louverSelection ? userSelection.louverSelection.toString() : '')
            .replace('{d2}', userSelection.d2 ? `(${userSelection.d2})` : '')
            .replace('{d2_plus_5}', userSelection.d2 ? `(${userSelection.d2 + 5})` : '');
    }
}